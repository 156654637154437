<template>
  <div>
    <div class="w-screen section-container laybare-banner relative space-top text-white-1">
      <div class="laybare-intro px-4 z-10 max-w-7xl text-center h-full flex flex-col gap-8 items-center justify-center mx-auto">
        <h2 class="banner-heading text-3xl mobileL:text-4xl xl:text-6xl 2xl:text-7xl font-semibold">Find My Local Lay Bare</h2>
        <p class="font-century text-base mobileL:text-xl xl:text-3xl 2xl:text-4xl">We're making sure that there's a Lay Bare store accessible to everyone</p>

        <div class="input-wrapper w-4/5 mobileL:w-3/5 mx-auto">
            <input class="input-box font-bold text-xl mobileL:font-normal mobileL:text-base xl:text-xl 2xl:text-3xl" type="text" placeholder="Search Branch" v-model="searchStr" @keyup.enter="searchBranch">
        </div>
      </div>
    </div>
    <div class="w-full map-section bg-brown-7" id="map" v-show="!shouldShowElement">
      <div class="max-w-screen-lg mobileL:max-w-screen-2xl 2xl:max-w-screen-3xl mx-auto px-5 py-8 xl:px-8 xl:py-12">
          <div class="map-container rounded-none mobileL:rounded-3xl">
              <Map :businessUnitId="$layBareId" :branchToSearch.sync="branchProps"/>
          </div>
      </div>
    </div>

    <div class="w-full pt-7 bg-brown-7" id="location" v-show="shouldShowElement">
      <div class="max-w-screen-2xl 2xl:max-w-screen-3xl mx-auto px-5 pb-8 xl:px-8 xl:py-12">
          <div class="rounded-none">
              <MapMobile @branchesLength="handleBranchesLength" @toggleMap="handleToggleMap" :businessUnitId="$layBareId" :branchToSearch.sync="branchProps"/>
              <div class="pt-5 h-96" v-show="showMapLocation || this.branchesLength > 0">
                <MapLocation :businessUnitId="$layBareId" :branchToSearch.sync="branchProps"></MapLocation>
              </div>
          </div>
      </div>
    </div>
  </div>
</template>

<script>
import Map from '../../components/Map.vue'
import MapLocation from '../../components/MapLocation.vue'
import MapMobile from '../../components/MapMobile.vue';
export default {
  components: {
      Map,
      MapLocation,
      MapMobile
    
  },

  data(){
      return {
          branches: [],
          searchStr: '',
          branchProps: '',
          windowWidth: window.innerWidth,
          branchesLength:'',
          showMapLocation: false,
      }
  },

  mounted() {
      window.addEventListener('resize', this.handleResize);
  },
  beforeDestroy() {
      window.removeEventListener('resize', this.handleResize);
  },

  computed: {
    shouldShowElement() {
    return this.windowWidth < 480;
    },  
  },
  methods: {
    handleToggleMap(show) {
      this.showMapLocation = show;
    },
    handleResize() {
      this.windowWidth = window.innerWidth;
    },
    handleBranchesLength(length) {
      this.branchesLength = length;
      this.showMapLocation = false;
    },
    searchBranch() {
      const currentSearch = this.searchStr;
      this.branchesLength = '';
      this.branchProps = null;
      this.$nextTick(() => {
        this.branchProps = currentSearch;
      });
    }
  }
}
</script>

<style scoped>

.section-container {
  height: 600px;
}

.space-top {
  margin-top: 90px;
}

.laybare-banner {
  background: url('../../assets/images/location-page/lb-location-banner.jpg') no-repeat;
  background-size: 100% 100%;
}

.banner-heading {
    text-shadow: 2px 5px #9B2E5F99;
}

.input-wrapper {
  position: relative;
}

.input-box {
  padding: 10px 0;
  border: none;
  border-bottom: 2px solid #fefefe;;
  color: #fef6ef;
  width: 100%;
  background-color: transparent;
  text-align: center;
}

.input-box:focus {
  outline: none;
}

.input-box::placeholder {
    color: #fefefe;
    font-size: 1.3rem;
}

.map-container {
  height: 800px;
}

@media (max-width: 480px) {
  .space-top {
    margin-top: 60px;
  }
  .section-container {
    height: 420px;
  }
  
  .laybare-banner {
  background: url('../../assets/images/location-page/lb-location-banner-mobile.jpg') no-repeat;
  background-size: 100% 100%;
  }
 
  .map-section {
    height: 480px;
  }

  .map-container {
    height: 420px;
  }

}

@media (min-width: 1280px) {
  .space-top {
    margin-top: 100px;
  }

  .section-container {
    height: 800px;
  }

  .input-box::placeholder {
    font-size: 1.8rem;
  }
}

@media (min-width: 1536px) {
  .space-top {
    margin-top: 128px;
  }

  .section-container {
    height: 110vh;
  }

  .input-box::placeholder {
    font-size: 2rem;
  }
}
</style>